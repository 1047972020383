import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ARTCC_IDS } from "@vatsim-vnas/js-libs/constants";
import React, { useMemo, useState } from "react";
import { Card, Table } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { useSearchParams } from "react-router-dom";
import { TableNoRows } from "src/components/ui";
import { AircraftModal } from "src/components/ui/modal";
import { aircraftSelector, useAppSelector } from "src/redux";
import { getArtccIdList } from "src/utils";

function Aircraft() {
  const aircraft = useAppSelector(aircraftSelector);
  const [searchParams, setSearchParams] = useSearchParams();
  const search = searchParams.get("search") ?? "";
  const artccsFilter = useMemo(() => JSON.parse(searchParams.get("artccs") ?? "[]"), [searchParams.get("artccs")]);
  const [showModal, setShowModal] = useState(!!searchParams.get("open"));
  const [selectedAircraftId, setSelectedAircraftId] = useState(searchParams.get("open") ?? undefined);

  const visibleAircraft = useMemo(
    () =>
      aircraft
        .filter(
          (a) =>
            a.id.toLowerCase().includes(search.toLowerCase()) ||
            a.pilotUserId.toLowerCase().includes(search.toLowerCase()),
        )
        .filter(
          (a) =>
            !artccsFilter.length ||
            getArtccIdList(a.isWithinSurveillanceAoi).filter((x) => artccsFilter.includes(x)).length,
        )
        .sort((a, b) => a.id.localeCompare(b.id)),
    [JSON.stringify(aircraft), search, artccsFilter],
  );

  return (
    <div>
      <h1 className="content-header">Aircraft</h1>
      <section className="content">
        <Card>
          <Card.Header>
            <Card.Title>Aircraft</Card.Title>
            <div className="card-tools d-flex">
              <Typeahead
                id="artcc-search"
                className="mr-3 w-auto"
                options={ARTCC_IDS}
                defaultSelected={artccsFilter}
                flip
                placeholder="Filter ARTCCs..."
                paginate
                multiple
                onChange={(t) =>
                  setSearchParams((p) => {
                    p.set("artccs", JSON.stringify(t));
                    return p;
                  })
                }
              />
              <input
                className="form-control w-auto"
                value={search}
                onChange={(e) =>
                  setSearchParams((p) => {
                    p.set("search", e.target.value);
                    return p;
                  })
                }
                placeholder="Search..."
              />
            </div>
          </Card.Header>
          <Card.Body>
            <Table responsive striped className="table-head-fixed">
              <thead>
                <tr>
                  <th>Aircraft ID</th>
                  <th>User ID</th>
                  <th>ARTCCs</th>
                  <th className="w-0">View</th>
                </tr>
              </thead>
              <tbody>
                {visibleAircraft.map((a) => (
                  <tr key={a.id}>
                    <td>{a.id}</td>
                    <td>{a.pilotUserId}</td>
                    <td>{getArtccIdList(a.isWithinSurveillanceAoi).join(", ")}</td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          setShowModal(true);
                          setSelectedAircraftId(a.id);
                        }}
                      >
                        <FontAwesomeIcon icon={faMagnifyingGlass} />
                      </button>
                    </td>
                  </tr>
                ))}
                <TableNoRows rows={visibleAircraft} text="No Aircraft" />
              </tbody>
            </Table>
          </Card.Body>
          <Card.Footer />
        </Card>
      </section>
      <AircraftModal
        show={showModal}
        aircraft={aircraft.find((a) => a.id === selectedAircraftId)}
        onClose={() => setShowModal(false)}
      />
    </div>
  );
}

export default Aircraft;
