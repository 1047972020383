import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getNasStatusAsync, loginAsync, refreshTokenAsync } from "@vatsim-vnas/js-libs/api/vnas";
import { Response } from "@vatsim-vnas/js-libs/models/api";
import { Environment } from "@vatsim-vnas/js-libs/models/vnas";
import { tokenHasExpired } from "@vatsim-vnas/js-libs/utils";
import * as jose from "jose";
import { toast } from "react-toastify";
import { VATSIM_CLIENT_ID } from "src/utils/constants";
import { RootState } from "../store";

interface LoginProps {
  code: string;
  redirectUrl: string;
}

interface LoginSpec {
  authenticationEnvironment: Environment;
  nasToken: string;
  vatsimToken: string;
}

const getLocalVatsimToken = () => {
  const vatsimToken = localStorage.getItem("vatsim-token");
  if (!vatsimToken) {
    return undefined;
  }

  const decodedToken = jose.decodeJwt(vatsimToken);
  if (!tokenHasExpired(decodedToken)) {
    return vatsimToken;
  }

  return undefined;
};

const loginWithEnvironment = async (environment: Environment, loginProps: LoginProps): Promise<Response<LoginSpec>> => {
  let statusText: string;
  try {
    const res = await loginAsync(environment.apiBaseUrl, loginProps.code, loginProps.redirectUrl, VATSIM_CLIENT_ID);
    if (res.ok) {
      const dataRes = await getNasStatusAsync(environment.apiBaseUrl, res.data!.nasToken);
      if (dataRes.ok) {
        return {
          ok: true,
          statusText: res.statusText,
          data: {
            nasToken: res.data!.nasToken,
            vatsimToken: res.data!.vatsimToken,
            authenticationEnvironment: environment,
          },
        };
      }
      return { ok: false, statusText: "User is not admin." };
    }
    statusText = res.statusText;
    if (environment.isPrimary) {
      toast.warn("Failed to log in with primary environment. Trying secondary environments...", { autoClose: 10000 });
    }
  } catch (e) {
    statusText = `${e}`;
    if (environment.isPrimary) {
      toast.warn("Failed to log in with primary environment. Trying secondary environments...", { autoClose: 10000 });
    }
  }
  return { ok: false, statusText };
};

const refreshNasTokenWithEnvironment = async (
  environment: Environment,
  vatsimToken: string,
): Promise<Response<LoginSpec>> => {
  let statusText: string;
  try {
    const res = await refreshTokenAsync(environment.apiBaseUrl, vatsimToken);
    if (res.ok) {
      const dataRes = await getNasStatusAsync(environment.apiBaseUrl, res.data!);
      if (dataRes.ok) {
        return {
          ok: true,
          statusText: res.statusText,
          data: {
            nasToken: res.data!,
            vatsimToken,
            authenticationEnvironment: environment,
          },
        };
      }
      return { ok: false, statusText: "User is not admin." };
    }
    statusText = res.statusText;
    if (environment.isPrimary) {
      toast.warn("Failed to log in with primary environment. Trying secondary environments...", { autoClose: 10000 });
    }
  } catch (e) {
    statusText = `${e}`;
    if (environment.isPrimary) {
      toast.warn("Failed to log in with primary environment. Trying secondary environments...", { autoClose: 10000 });
    }
  }
  return { ok: false, statusText };
};

export const login = createAsyncThunk("auth/login", async (loginProps: LoginProps, thunkApi) => {
  const availableEnvironments = (thunkApi.getState() as RootState).status.availableEnvironments
    .filter((e) => !e.isDisabled)
    .sort((a, b) => (b.isPrimary ? 1 : 0) - (a.isPrimary ? 1 : 0));

  if (!availableEnvironments.length) {
    toast.error("Failed to log in: No environments found.");
    return undefined;
  }

  let statusText = "";
  for (const environment of availableEnvironments) {
    const res = await loginWithEnvironment(environment, loginProps);
    if (res.ok) {
      return res.data!;
    }
    statusText = res.statusText;
  }

  toast.error(`Failed to log in: ${statusText}`);
  return undefined;
});

export const refreshNasToken = createAsyncThunk("auth/refreshNasToken", async (_, thunkApi) => {
  const { availableEnvironments, environment } = (thunkApi.getState() as RootState).status;
  const vatsimToken = (thunkApi.getState() as RootState).auth.vatsimToken!;
  let statusText = "";

  if (environment && !environment.isDisabled) {
    const res = await refreshNasTokenWithEnvironment(environment, vatsimToken);
    if (res.ok) {
      return res.data!;
    }
    statusText = res.statusText;
  }

  if (!availableEnvironments.length) {
    toast.error("Failed to log in: No environments found.");
    return undefined;
  }

  for (const environment of availableEnvironments) {
    const res = await refreshNasTokenWithEnvironment(environment, vatsimToken);
    if (res.ok) {
      return res.data!;
    }
    statusText = res.statusText;
  }

  toast.error(`Failed to log in: ${statusText}`);
  return undefined;
});

interface AuthState {
  authenticationEnvironment?: Environment;
  nasToken?: string;
  vatsimCode?: string;
  vatsimToken?: string;
}

const initialState: AuthState = {
  vatsimToken: getLocalVatsimToken(),
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, action) => {
      if (action.payload) {
        state.nasToken = action.payload.nasToken;
        state.vatsimToken = action.payload.vatsimToken;
        state.authenticationEnvironment = action.payload.authenticationEnvironment;
        localStorage.setItem("vatsim-token", action.payload.vatsimToken);
      }
    });
    builder.addCase(login.rejected, (state, action) => {
      state.nasToken = undefined;
      state.vatsimToken = undefined;
      state.vatsimCode = undefined;
      localStorage.removeItem("vatsim-token");
      toast.error(`Failed to log in: ${action.error.message}`);
    });
    builder.addCase(refreshNasToken.fulfilled, (state, action) => {
      if (action.payload) {
        state.nasToken = action.payload.nasToken;
        state.authenticationEnvironment = action.payload.authenticationEnvironment;
      } else {
        state.nasToken = undefined;
        state.vatsimToken = undefined;
        state.vatsimCode = undefined;
      }
    });
    builder.addCase(refreshNasToken.rejected, (state, action) => {
      state.nasToken = undefined;
      state.vatsimToken = undefined;
      state.vatsimCode = undefined;
      toast.error(`Failed to log in: ${action.error.message}`);
    });
  },
  reducers: {
    setVatsimCode(state, action: { payload: string }) {
      state.vatsimCode = action.payload;
    },
    logout(state) {
      state.nasToken = undefined;
      state.vatsimToken = undefined;
      state.vatsimCode = undefined;
      localStorage.removeItem("vatsim-token");
      toast.success("Successfully logged out");
    },
  },
});

export const { setVatsimCode, logout } = authSlice.actions;

export const vatsimCodeSelector = (state: RootState) => state.auth.vatsimCode;
export const vatsimTokenSelector = (state: RootState) => state.auth.vatsimToken;
export const authenticationEnvironmentSelector = (state: RootState) => state.auth.authenticationEnvironment;
export const userIdSelector = (state: RootState) => jose.decodeJwt(state.auth.vatsimToken!).sub!;
export const nasTokenSelector = (state: RootState) => state.auth.nasToken;
export const loggedInSelector = (state: RootState) => !!state.auth.nasToken;

export default authSlice.reducer;
