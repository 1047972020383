import { faHeadset, faLaptop, faUser, faWifi } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Position } from "@vatsim-vnas/js-libs/models/facilities";
import { NetworkRating, networkRatingToString } from "@vatsim-vnas/js-libs/models/vnas/common";
import { fsdFacilityTypeToString } from "@vatsim-vnas/js-libs/models/vnas/fsd";
import { SessionStatus } from "@vatsim-vnas/js-libs/models/vnas/status";
import { dateToZuluString, frequencyToString, getElapsedTime } from "@vatsim-vnas/js-libs/utils";
import React from "react";
import { Badge, Card, Col, Modal, Row, Table } from "react-bootstrap";

interface SessionModalProps {
  session: SessionStatus | undefined;
  show: boolean;
  onClose: () => void;
}

function getNetworkRatingColor(networkRating: NetworkRating) {
  switch (networkRating) {
    case NetworkRating.Student1:
    case NetworkRating.Student2:
    case NetworkRating.Student3:
      return "info";
    case NetworkRating.Controller1:
    case NetworkRating.Controller2:
    case NetworkRating.Controller3:
      return "success";
    case NetworkRating.Instructor1:
    case NetworkRating.Instructor2:
    case NetworkRating.Instructor3:
      return "danger";
    case NetworkRating.Supervisor:
    case NetworkRating.Administrator:
      return "warning";
    case NetworkRating.Observer:
    default:
      return "secondary";
  }
}

function getSectorId(position: Position) {
  if (position.eramConfiguration) {
    return position.eramConfiguration.sectorId;
  }

  if (position.starsConfiguration) {
    return position.starsConfiguration.subset + position.starsConfiguration.sectorId;
  }

  return "";
}

function SessionModal({ session, show, onClose }: Readonly<SessionModalProps>) {
  if (!session) {
    return undefined;
  }

  return (
    <Modal show={show} onHide={onClose} backdrop="static" size="xl">
      <Modal.Header className="dark-mode">
        <Modal.Title>Session</Modal.Title>
      </Modal.Header>
      {session && (
        <Modal.Body className="dark-mode d-flex flex-wrap gap-3">
          <Card className="flex-grow-1">
            <Card.Header>
              <Card.Title>
                <FontAwesomeIcon icon={faLaptop} className="mr-2" />
                Session Details
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col md className="mb-3">
                  Session ID: <b>{session.id}</b>
                </Col>
                <Col md className="mb-3">
                  Connections: <b>{session.connections.length}</b>
                </Col>
              </Row>
              <Row>
                <Col md className="mb-3">
                  Started at: <b>{dateToZuluString(session.startedAt)}</b>
                </Col>
                <Col md>
                  Elapsed time: <b>{getElapsedTime(session.startedAt)}</b>
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer />
          </Card>
          <Card className="flex-grow-1">
            <Card.Header>
              <Card.Title>
                <FontAwesomeIcon icon={faUser} className="mr-2" />
                User
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Col>
                <Row>
                  Real name:&nbsp;&nbsp;<b>{session.realName}</b>
                </Row>
                <Row className="mt-3">
                  User ID:&nbsp;&nbsp;<b>{session.userId}</b>
                </Row>
                <Row className="mt-3">
                  <span>Rating:</span>
                  <span className="ml-2">
                    <Badge bg={getNetworkRatingColor(session.userRating)}>
                      {networkRatingToString(session.userRating)}
                    </Badge>
                  </span>
                </Row>
                {session.requestedRating !== session.userRating && (
                  <Row className="mt-3">
                    <span>Requested Rating:</span>
                    <span className="ml-2">
                      <Badge bg={getNetworkRatingColor(session.requestedRating)}>
                        {networkRatingToString(session.requestedRating)}
                      </Badge>
                    </span>
                  </Row>
                )}
              </Col>
            </Card.Body>
            <Card.Footer />
          </Card>
          <Card className="flex-grow-1">
            <Card.Header>
              <Card.Title>
                <FontAwesomeIcon icon={faHeadset} className="mr-2" />
                Controlling Details&nbsp;&nbsp;
                <Badge bg={session.isActive ? "success" : "secondary"}>
                  {session.isActive ? "Active" : "Inactive"}
                </Badge>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col lg className="mb-3">
                  <Row>
                    ARTCC:&nbsp;&nbsp;<b>{session.artccId}</b>
                  </Row>
                  <Row className="mt-3">
                    Callsign:&nbsp;&nbsp;<b>{session.callsign}</b>
                  </Row>
                  <Row className="mt-3">
                    Facility Type:&nbsp;&nbsp;<b>{fsdFacilityTypeToString(session.fsdFacilityType)}</b>
                  </Row>
                  <Row className="mt-3">
                    Role:&nbsp;&nbsp;<b>{session.role}</b>
                  </Row>
                </Col>
                <Col lg className="mb-3">
                  <Row>Visiblity Centers:</Row>
                  {session.visibilityCenters.map((v) => (
                    <Row key={`${v.lat}${v.lon}`}>
                      <>
                        <b>{v.lat}</b>&deg;,&nbsp;<b>{v.lon}</b>&deg;
                      </>
                    </Row>
                  ))}
                </Col>
                <Col lg className="mb-3">
                  <Row>Controller Info:</Row>
                  <Row>
                    <b>{session.controllerInfo}</b>
                  </Row>
                </Col>
              </Row>
              <Row>Positions:</Row>
              <Row className="mt-2">
                <Table responsive striped className="table-head-fixed">
                  <thead>
                    <tr>
                      <th>&nbsp;</th>
                      <th>Facility</th>
                      <th>Position</th>
                      <th>Radio Name</th>
                      <th>Frequency</th>
                      <th>Sector ID / TCP</th>
                    </tr>
                  </thead>
                  <tbody>
                    {[...session.positions]
                      .sort((a) => (a.isPrimary ? -1 : 1))
                      .map((p) => (
                        <tr key={p.position.id}>
                          <td>{p.isPrimary && <Badge bg="warning">Primary</Badge>}</td>
                          <td>{p.facilityId}</td>
                          <td>{p.position.name}</td>
                          <td>{p.position.radioName}</td>
                          <td>{frequencyToString(p.position.frequency)}</td>
                          <td>{getSectorId(p.position)}</td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </Row>
            </Card.Body>
            <Card.Footer />
          </Card>
          <Card className="flex-grow-1">
            <Card.Header>
              <Card.Title>
                <FontAwesomeIcon icon={faWifi} className="mr-2" />
                Connections
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Table striped responsive className="table-head-fixed">
                  <thead>
                    <tr>
                      <th>&nbsp;</th>
                      <th>ID</th>
                      <th>IP Address</th>
                      <th>Client Name</th>
                      <th>Client Version</th>
                      <th>Connected At</th>
                    </tr>
                  </thead>
                  <tbody>
                    {[...session.connections]
                      .sort((s) => (s.isPrimary ? -1 : 1))
                      .map((c) => (
                        <tr key={c.id}>
                          <td>{c.isPrimary && <Badge bg="warning">Primary</Badge>}</td>
                          <td>{c.id}</td>
                          <td>{c.ipAddress}</td>
                          <td>{c.clientName}</td>
                          <td>{c.clientVersion}</td>
                          <td>
                            {dateToZuluString(c.connectedAt)}&nbsp;&nbsp;<em>({getElapsedTime(c.connectedAt)})</em>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </Row>
            </Card.Body>
            <Card.Footer />
          </Card>
        </Modal.Body>
      )}
      <Modal.Footer className="dark-mode">
        <button className="btn btn-primary" onClick={onClose} type="button">
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default SessionModal;
