import { faClipboard, faClock, faRoute, faWrench } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { voiceTypeToString } from "@vatsim-vnas/js-libs/models/vnas/common";
import { FsdFlightPlan } from "@vatsim-vnas/js-libs/models/vnas/fsd";
import {
  beaconCodeToString,
  booleanToString,
  dateToZuluString,
  getTimeUntil,
  isDateInPast,
} from "@vatsim-vnas/js-libs/utils";
import React from "react";
import { Card, Col, Modal, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

interface FsdFlightPlanModalProps {
  flightPlan: FsdFlightPlan | undefined;
  show: boolean;
  onClose: () => void;
}

function FsdFlightPlanModal({ flightPlan, show, onClose }: FsdFlightPlanModalProps) {
  const navigate = useNavigate();

  if (!flightPlan) {
    return undefined;
  }

  return (
    <Modal show={show} onHide={onClose} backdrop="static" size="xl">
      <Modal.Header className="dark-mode">
        <Modal.Title>{flightPlan.aircraftId}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="dark-mode d-flex flex-wrap gap-3">
        <Card className="flex-grow-1">
          <Card.Header>
            <Card.Title>
              <FontAwesomeIcon icon={faClipboard} className="mr-2" />
              General
            </Card.Title>
          </Card.Header>
          <Card.Body>
            <Row>
              <Col>
                Aircraft ID: <b>{flightPlan.aircraftId}</b>
              </Col>
              <Col className="mb-3">
                Revision: <b>{flightPlan.revision}</b>
              </Col>
            </Row>
            <Row>
              <Col md className="mb-3">
                Created locally: <b>{booleanToString(flightPlan.createdLocally)}</b>
              </Col>
              <Col md className="mb-3">
                Expires:{" "}
                {!isDateInPast(flightPlan.expiresAt) && (
                  <b>
                    {dateToZuluString(flightPlan.expiresAt)} <em>{`(${getTimeUntil(flightPlan.expiresAt)})`}</em>
                  </b>
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                Locked: <b>{booleanToString(flightPlan.hardLock)}</b>
              </Col>
              <Col>
                Locked by: <b>{flightPlan.lockedBy}</b>
              </Col>
            </Row>
          </Card.Body>
          <Card.Footer />
        </Card>
        <Card className="flex-grow-1">
          <Card.Header>
            <Card.Title>
              <FontAwesomeIcon icon={faRoute} className="mr-2" />
              Routing
            </Card.Title>
          </Card.Header>
          <Card.Body>
            <Row>
              <Col md className="mb-3">
                Departure: <b>{flightPlan.departure}</b>
              </Col>
              <Col md className="mb-3">
                Destination: <b>{flightPlan.destination}</b>
              </Col>
              <Col md className="mb-3">
                Alternate: <b>{flightPlan.alternate}</b>
              </Col>
            </Row>
            <Row>
              <Col md className="mb-3">
                Altitude: <b>{flightPlan.altitude}</b>ft
              </Col>
              <Col md className="mb-3">
                Speed: <b>{flightPlan.speed}</b> kts
              </Col>
              <Col md className="mb-3">
                IFR: <b>{booleanToString(flightPlan.isIfr)}</b>
              </Col>
            </Row>
            <Row>
              <Col>
                Route: <b>{flightPlan.route}</b>
              </Col>
            </Row>
          </Card.Body>
          <Card.Footer />
        </Card>
        <Card className="flex-grow-1">
          <Card.Header>
            <Card.Title>
              <FontAwesomeIcon icon={faWrench} className="mr-2" />
              Equipment
            </Card.Title>
          </Card.Header>
          <Card.Body>
            <Row>
              <Col className="mb-3">
                Aircraft type: <b>{flightPlan.aircraftType}</b>
              </Col>
            </Row>
            <Row>
              <Col lg className="mb-3">
                Equipment: <b>{flightPlan.equipment}</b>
              </Col>
              <Col lg className="mb-3">
                Wake turbulence code: <b>{flightPlan.wakeTurbulenceCode}</b>
              </Col>
            </Row>
            <Row>
              <Col lg className="mb-3">
                FAA equipment code: <b>{flightPlan.faaEquipmentSuffix}</b>
              </Col>
              <Col lg className="mb-3">
                ICAO equipment codes: <b>{flightPlan.icaoEquipmentCodes}</b>
              </Col>
              <Col lg>
                ICAO surveillance codes: <b>{flightPlan.icaoSurveillanceCodes}</b>
              </Col>
            </Row>
          </Card.Body>
          <Card.Footer />
        </Card>
        <Card className="flex-grow-1">
          <Card.Header>
            <Card.Title>
              <i className="fa-solid fa-radar mr-2" />
              Beacon Code
            </Card.Title>
          </Card.Header>
          <Card.Body>
            <Row>
              <Col className="mb-3">
                Assigned beacon code:{" "}
                <b>{flightPlan.assignedBeaconCode && beaconCodeToString(flightPlan.assignedBeaconCode)}</b>
              </Col>
            </Row>
            <Row>
              <Col className="mb-3">
                Assigned by: <b>{flightPlan.beaconCodeSourceArtccId}</b>
              </Col>
            </Row>
            <Row>
              <Col>
                Assigned at:{" "}
                <b>{flightPlan.beaconCodeAssignedAt && dateToZuluString(flightPlan.beaconCodeAssignedAt)}</b>
              </Col>
            </Row>
          </Card.Body>
          <Card.Footer />
        </Card>
        <Card className="flex-grow-1">
          <Card.Header>
            <Card.Title>
              <FontAwesomeIcon icon={faClock} className="mr-2" />
              Timing
            </Card.Title>
          </Card.Header>
          <Card.Body>
            <Row>
              <Col md className="mb-3">
                Estimated departure time: <b>{flightPlan.estimatedDepartureTime.toString().padStart(4, "0")}</b>
              </Col>
              <Col md className="mb-3">
                Actual departure time: <b>{flightPlan.actualDepartureTime.toString().padStart(4, "0")}</b>
              </Col>
            </Row>
            <Row>
              <Col md className="mb-3">
                Time enroute:{" "}
                <b>
                  {flightPlan.hoursEnroute}h{flightPlan.minutesEnroute}m
                </b>
              </Col>
              <Col md>
                Available fuel:{" "}
                <b>
                  {flightPlan.fuelHours}h{flightPlan.fuelMinutes}m
                </b>
              </Col>
            </Row>
          </Card.Body>
          <Card.Footer />
        </Card>
        <Card className="flex-grow-1">
          <Card.Header>
            <Card.Title>
              <i className="fa-solid fa-user-pilot mr-2" />
              Pilot
            </Card.Title>
          </Card.Header>
          <Card.Body>
            <Row>
              <Col md className="mb-3">
                User ID: <b>{flightPlan.pilotCid}</b>
              </Col>
              <Col md className="mb-3">
                Voice type: <b>{voiceTypeToString(flightPlan.voiceType)}</b>
              </Col>
            </Row>
            <Row>
              <Col>
                Remarks: <b>{flightPlan.remarks}</b>
              </Col>
            </Row>
          </Card.Body>
          <Card.Footer />
        </Card>
      </Modal.Body>
      <Modal.Footer className="dark-mode">
        <button
          className="btn btn-success"
          type="button"
          onClick={() => navigate(`/aircraft?open=${flightPlan.aircraftId}`)}
        >
          View Aircraft
        </button>
        <button className="btn btn-primary" onClick={onClose} type="button">
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default FsdFlightPlanModal;
