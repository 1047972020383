import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ARTCC_IDS } from "@vatsim-vnas/js-libs/constants";
import { FlightPlan } from "@vatsim-vnas/js-libs/models/vnas/flight-data";
import React, { useMemo, useState } from "react";
import { Card, Table } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { useSearchParams } from "react-router-dom";
import { TableNoRows } from "src/components/ui";
import { FlightPlanModal } from "src/components/ui/modal";
import { flightPlansSelector, useAppSelector } from "src/redux";

function FlightPlans() {
  const flightPlans = useAppSelector(flightPlansSelector);
  const [searchParams, setSearchParams] = useSearchParams();
  const search = searchParams.get("search") ?? "";
  const artccsFilter = useMemo(() => JSON.parse(searchParams.get("artccs") ?? "[]"), [searchParams.get("artccs")]);
  const [showModal, setShowModal] = useState(false);
  const [selectedFlightPlanSpec, setSelectedFlightPlanSpec] = useState<[string, string]>();

  const visibleFlightPlans = useMemo(() => {
    if (!flightPlans) {
      return [];
    }

    const flightPlanSpecs: [string, FlightPlan][] = [];

    Object.entries(flightPlans)
      .filter((f) => !artccsFilter.length || artccsFilter.includes(f[0]))
      .forEach((artccFlightPlans) => {
        const [artccId, fps] = artccFlightPlans;
        fps
          .filter(
            (fp) =>
              fp.aircraftId.toLowerCase().includes(search.toLowerCase()) ||
              fp.departure.toLowerCase().includes(search.toLowerCase()) ||
              fp.destination.toLowerCase().includes(search.toLowerCase()),
          )
          .forEach((fp) => {
            flightPlanSpecs.push([artccId, fp]);
          });
      });
    return flightPlanSpecs.sort((a, b) => a[1].aircraftId.localeCompare(b[1].aircraftId));
  }, [JSON.stringify(flightPlans), search, artccsFilter]);

  return (
    <div>
      <h1 className="content-header">vNAS Flight Plans</h1>
      <section className="content">
        <Card>
          <Card.Header>
            <h3 className="card-title">vNAS Flight Plans</h3>
            <div className="card-tools d-flex">
              <Typeahead
                id="artcc-search"
                className="mr-3 w-auto"
                options={ARTCC_IDS}
                defaultSelected={artccsFilter}
                flip
                placeholder="Filter ARTCCs..."
                paginate
                multiple
                onChange={(t) =>
                  setSearchParams((p) => {
                    p.set("artccs", JSON.stringify(t));
                    return p;
                  })
                }
              />
              <input
                className="form-control w-auto"
                value={search}
                onChange={(e) =>
                  setSearchParams((p) => {
                    p.set("search", e.target.value);
                    return p;
                  })
                }
                placeholder="Search..."
              />
            </div>
          </Card.Header>
          <Card.Body>
            <Table responsive striped className="table-head-fixed">
              <thead>
                <tr>
                  <th>ARTCC ID</th>
                  <th>Aircraft ID</th>
                  <th>Departure</th>
                  <th>Destination</th>
                  <th className="w-0">View</th>
                </tr>
              </thead>
              <tbody>
                {visibleFlightPlans.map((fp) => (
                  <tr key={fp[1].cid + fp[0]}>
                    <td>{fp[0]}</td>
                    <td>{fp[1].aircraftId}</td>
                    <td>{fp[1].departure}</td>
                    <td>{fp[1].destination}</td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          setShowModal(true);
                          setSelectedFlightPlanSpec([fp[0], fp[1].aircraftId]);
                        }}
                      >
                        <FontAwesomeIcon icon={faMagnifyingGlass} />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
              <TableNoRows rows={visibleFlightPlans} text="No Flight Plans" />
            </Table>
          </Card.Body>
          <Card.Footer />
        </Card>
      </section>
      {flightPlans && (
        <FlightPlanModal
          show={showModal}
          flightPlan={
            selectedFlightPlanSpec?.[0]
              ? flightPlans[selectedFlightPlanSpec[0]].find((fp) => fp.aircraftId === selectedFlightPlanSpec[1])
              : undefined
          }
          artccId={selectedFlightPlanSpec ? selectedFlightPlanSpec[0] : undefined}
          onClose={() => setShowModal(false)}
        />
      )}
    </div>
  );
}

export default FlightPlans;
