import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useMemo, useState } from "react";
import { Card, Table } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import { TableNoRows } from "src/components/ui";
import { FsdFlightPlanModal } from "src/components/ui/modal";
import { fsdFlightPlansSelector, useAppSelector } from "src/redux";

function FsdFlightPlans() {
  const flightPlans = useAppSelector(fsdFlightPlansSelector);

  const [searchParams, setSearchParams] = useSearchParams();
  const search = searchParams.get("search") ?? "";
  const [showModal, setShowModal] = useState(!!searchParams.get("open"));
  const [selectedAircraftId, setSelectedAircraftId] = useState<string | undefined>(
    searchParams.get("open") ?? undefined,
  );

  const visibleFlightPlans = useMemo(
    () =>
      flightPlans
        .filter(
          (fp) =>
            fp.aircraftId.toLowerCase().includes(search.toLowerCase()) ||
            fp.departure.toLowerCase().includes(search.toLowerCase()) ||
            fp.destination.toLowerCase().includes(search.toLowerCase()),
        )
        .sort((a, b) => a.aircraftId.localeCompare(b.aircraftId)),
    [JSON.stringify(flightPlans), search],
  );

  return (
    <>
      <h1 className="content-header">FSD Flight Plans</h1>
      <section className="content">
        <Card>
          <Card.Header>
            <Card.Title>FSD Flight Plans</Card.Title>
            <div className="card-tools">
              <input
                className="form-control w-auto"
                value={search}
                onChange={(e) => setSearchParams({ search: e.target.value })}
                placeholder="Search..."
              />
            </div>
          </Card.Header>
          <Card.Body>
            <Table responsive striped className="table-head-fixed">
              <thead>
                <tr>
                  <th>Aircraft ID</th>
                  <th>Departure</th>
                  <th>Destination</th>
                  <th className="w-0">View</th>
                </tr>
              </thead>
              <tbody>
                {visibleFlightPlans.map((fp) => (
                  <tr key={fp.aircraftId}>
                    <td>{fp.aircraftId}</td>
                    <td>{fp.departure}</td>
                    <td>{fp.destination}</td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          setShowModal(true);
                          setSelectedAircraftId(fp.aircraftId);
                        }}
                      >
                        <FontAwesomeIcon icon={faMagnifyingGlass} />
                      </button>
                    </td>
                  </tr>
                ))}
                <TableNoRows rows={visibleFlightPlans} text="No Flight Plans" />
              </tbody>
            </Table>
          </Card.Body>
          <Card.Footer />
        </Card>
      </section>
      <FsdFlightPlanModal
        show={showModal}
        flightPlan={flightPlans.find((f) => f.aircraftId === selectedAircraftId)}
        onClose={() => setShowModal(false)}
      />
    </>
  );
}

export default FsdFlightPlans;
