import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ARTCC_IDS } from "@vatsim-vnas/js-libs/constants";
import { SessionStatus } from "@vatsim-vnas/js-libs/models/vnas/status";
import { getElapsedTime } from "@vatsim-vnas/js-libs/utils";
import React, { useEffect, useMemo, useState } from "react";
import { Card, Table } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { useSearchParams } from "react-router-dom";
import { TableNoRows } from "src/components/ui";
import { SessionModal } from "src/components/ui/modal";
import { nasStatusSelector, useAppSelector } from "src/redux";

function Sessions() {
  const nasStatus = useAppSelector(nasStatusSelector);
  const [searchParams, setSearchParams] = useSearchParams();
  const search = searchParams.get("search") ?? "";
  const artccsFilter = useMemo(() => JSON.parse(searchParams.get("artccs") ?? "[]"), [searchParams.get("artccs")]);
  const [selectedSessionId, setSelectedSessionId] = useState<string>();
  const [showSessionModal, setShowSessionModal] = useState<boolean>(false);
  const [visibleSessions, setVisibleSessions] = useState<SessionStatus[]>([]);

  useEffect(() => {
    if (nasStatus) {
      setVisibleSessions(
        [...nasStatus.sessions]
          .filter(
            (s) =>
              s.userId.toLowerCase().includes(search.toLowerCase()) ||
              s.callsign.toLowerCase().includes(search.toLowerCase()),
          )
          .filter((s) => !artccsFilter.length || artccsFilter.includes(s.artccId))
          .sort((a, b) => new Date(a.startedAt).getTime() - new Date(b.startedAt).getTime()),
      );
    }
  }, [search, artccsFilter, JSON.stringify(nasStatus?.sessions)]);

  return (
    <>
      <h1 className="content-header">Sessions</h1>
      <section className="content">
        <Card>
          <Card.Header>
            <Card.Title>Sessions</Card.Title>
            <div className="card-tools d-flex">
              <Typeahead
                id="artcc-search"
                className="mr-3 w-auto"
                options={ARTCC_IDS}
                defaultSelected={artccsFilter}
                flip
                placeholder="Filter ARTCCs..."
                paginate
                multiple
                onChange={(t) =>
                  setSearchParams((p) => {
                    p.set("artccs", JSON.stringify(t));
                    return p;
                  })
                }
              />
              <input
                className="form-control w-auto"
                value={search}
                onChange={(e) =>
                  setSearchParams((p) => {
                    p.set("search", e.target.value);
                    return p;
                  })
                }
                placeholder="Search..."
              />
            </div>
          </Card.Header>
          <Card.Body>
            <Table responsive striped className="table-head-fixed">
              <thead>
                <tr>
                  <th>ARTCC</th>
                  <th>User ID</th>
                  <th>Callsign</th>
                  <th>Role</th>
                  <th>Session Time</th>
                  <th className="w-0">View</th>
                </tr>
              </thead>
              <tbody>
                {visibleSessions.map((s) => (
                  <tr key={s.id}>
                    <td>{s.artccId}</td>
                    <td>{s.userId}</td>
                    <td>{s.callsign}</td>
                    <td>{s.role}</td>
                    <td>{getElapsedTime(s.startedAt)}</td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          setSelectedSessionId(s.id);
                          setShowSessionModal(true);
                        }}
                      >
                        <FontAwesomeIcon icon={faMagnifyingGlass} />
                      </button>
                    </td>
                  </tr>
                ))}
                <TableNoRows rows={visibleSessions} text="No Sessions" />
              </tbody>
            </Table>
          </Card.Body>
          <Card.Footer />
        </Card>
      </section>
      {nasStatus && (
        <SessionModal
          session={nasStatus.sessions.find((s) => s.id === selectedSessionId)}
          show={showSessionModal}
          onClose={() => setShowSessionModal(false)}
        />
      )}
    </>
  );
}

export default Sessions;
