import React, { useEffect } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { calculateScreenSize } from "./enums";
import { useWindowSize } from "./hooks";
import { Main } from "./modules";
import {
  Aircraft,
  Artcc,
  Artccs,
  ExternalControllers,
  FlightPlans,
  FsdFlightPlans,
  Login,
  PageNotFound,
  Sessions,
} from "./pages";
import { getVnasConfiguration, screenSizeSelector, setScreenSize, useAppDispatch, useAppSelector } from "./redux";
import { PrivateRoute } from "./routes";
import { addWindowClass } from "./utils";

function App() {
  const screenSize = useAppSelector(screenSizeSelector);
  const dispatch = useAppDispatch();
  const windowSize = useWindowSize();

  useEffect(() => {
    const size = calculateScreenSize(windowSize.width);
    if (screenSize !== size) {
      dispatch(setScreenSize(size));
    }
  }, [windowSize]);

  useEffect(() => {
    dispatch(getVnasConfiguration());
    addWindowClass("layout-navbar-fixed");
    addWindowClass("dark-mode");
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<PrivateRoute />}>
          <Route path="/" element={<Main />}>
            <Route path="/" element={<Navigate to="/artccs" />} />
            <Route path="/artccs" element={<Artccs />} />
            <Route path="/artccs/:id" element={<Artcc />} />
            <Route path="/sessions" element={<Sessions />} />
            <Route path="/aircraft" element={<Aircraft />} />
            <Route path="/flight-plans" element={<FlightPlans />} />
            <Route path="/fsd-flight-plans" element={<FsdFlightPlans />} />
            <Route path="/external-controllers" element={<ExternalControllers />} />
          </Route>
        </Route>
        <Route path="/404" element={<PageNotFound />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
